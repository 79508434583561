











































































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { is_not } from 'vee-validate/dist/rules'; // eslint-disable-line
import dayjs from 'dayjs';
import { InputErrorIcon } from '@/app/components';

extend('is_not', {
    ...is_not, // eslint-disable-line
    message: 'The {_field_} field is required',
});

export function cronToString(cronExpression: string, startDate: Date, endDate: Date, retrieveOnce = false): string {
    const cron = cronExpression.split(' ');
    let minutes = cron[0];
    let hours = cron[1];
    const dayOfMonth = cron[2];
    const dayOfWeek = cron[4];

    let result = 'Runs at ';

    // Formatting time if composed of zeros
    if (minutes === '0') {
        minutes = '00';
    } else if (minutes.length === 1 && minutes !== '*') {
        minutes = `0${minutes}`;
    }

    if (hours === '0') {
        hours = '00';
    } else if (hours.length === 1 && hours !== '*') {
        hours = `0${hours}`;
    }

    if (hours === '*') {
        result = `Runs every hour, at XX:${minutes}`;
    } else {
        result = `Runs at ${hours}:${minutes} `;

        if (retrieveOnce) {
            if (startDate) {
                result += 'on the ';
                const date = dayjs.utc(startDate).format(`D MMMM YYYY`).split(' ');
                const day = date[0];

                let dayText = '';
                if (day === '1' || day === '21' || day === '31') {
                    dayText += 'st ';
                } else if (day === '2' || day === '22') {
                    dayText += 'nd ';
                } else if (day === '3' || day === '23') {
                    dayText += 'rd ';
                } else {
                    dayText += 'th ';
                }

                result += `${day}${dayText} of ${date[1]} ${date[2]}`;
            }
        } else {
            if (dayOfWeek === '*' && dayOfMonth === '*') {
                result += 'every day';
            } else if (dayOfWeek === '*' && dayOfMonth !== '*') {
                result = `${result}on the ${dayOfMonth}`;
                if (dayOfMonth === '1' || dayOfMonth === '21' || dayOfMonth === '31') {
                    result += 'st ';
                } else if (dayOfMonth === '2' || dayOfMonth === '22') {
                    result += 'nd ';
                } else if (dayOfMonth === '3' || dayOfMonth === '23') {
                    result += 'rd ';
                } else {
                    result += 'th ';
                }
                result += 'day of every month';
                if (dayOfMonth === '29' || dayOfMonth === '30' || dayOfMonth === '31') {
                    result += ` (won't run if month has less than ${dayOfMonth} days)`;
                }
            } else if (dayOfWeek !== '*' && dayOfMonth === '*') {
                switch (parseInt(dayOfWeek, 10)) {
                    case 0:
                        result += 'on Sundays';
                        break;
                    case 1:
                        result += 'on Mondays';
                        break;
                    case 2:
                        result += 'on Tuesdays';
                        break;
                    case 3:
                        result += 'on Wednesdays';
                        break;
                    case 4:
                        result += 'on Thursdays';
                        break;
                    case 5:
                        result += 'on Fridays';
                        break;
                    case 6:
                        result += 'on Saturdays';
                        break;
                    default:
                        result = `Unreadable cron format. Cron will be displayed in its raw form: ${cronExpression}`;
                        return result;
                }
            }

            if (startDate) result += `, starting from ${dayjs.utc(startDate).format('D MMMM YYYY')}`;
            if (endDate) result += ` to ${dayjs.utc(endDate).format('D MMMM YYYY')}`;
        }
    }
    result += ` (UTC timezone applied)`;
    return result;
}

export interface CronSchedule {
    minutes: number | string;
    hours: number | string;
    dayOfMonth: number | string;
    month: number | string;
    dayOfWeek: number | string;
}

export default defineComponent({
    name: 'Schedule',
    model: {
        prop: 'schedule',
        event: 'changed',
    },
    components: {
        ValidationProvider,
        InputErrorIcon,
    },
    props: {
        period: {
            type: String,
            required: true,
        },
        startDate: {
            type: Date,
            default: null,
        },
        endDate: {
            type: Date,
            default: null,
        },
        schedule: {
            type: String,
            required: true,
        },
        showDeleteIcon: {
            type: Boolean,
            default: true,
        },
        whiteBackground: {
            type: Boolean,
            default: false,
        },
        retrieveOnce: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const scheduleObject = ref<CronSchedule | null>(null);
        const fromCronSchedule = (cronSchedule: string): CronSchedule => {
            const cron = cronSchedule.split(' ');
            return {
                minutes: cron[0],
                hours: cron[1],
                dayOfMonth: cron[2],
                month: cron[3],
                dayOfWeek: cron[4],
            };
        };

        const toCronSchedule = (schedule: CronSchedule): string => {
            const { minutes, hours, dayOfMonth, month, dayOfWeek } = schedule;
            return `${minutes} ${hours} ${dayOfMonth} ${month} ${dayOfWeek}`;
        };

        watch(
            () => props.schedule,
            (value: string) => {
                scheduleObject.value = fromCronSchedule(value);
            },
        );
        scheduleObject.value = fromCronSchedule(props.schedule);

        const scheduleChanged = () => {
            if (scheduleObject.value) {
                emit('changed', toCronSchedule(scheduleObject.value));
            }
        };
        const isValidSchedule = computed(() => {
            switch (props.period) {
                case 'hourly':
                    return scheduleObject.value?.minutes !== '*';
                case 'daily':
                    return scheduleObject.value?.minutes !== '*' && scheduleObject.value?.hours !== '*';
                case 'weekly':
                    return (
                        scheduleObject.value?.minutes !== '*' &&
                        scheduleObject.value?.hours !== '*' &&
                        scheduleObject.value?.dayOfWeek !== '*'
                    );
                case 'monthly':
                    return (
                        scheduleObject.value?.minutes !== '*' &&
                        scheduleObject.value?.hours !== '*' &&
                        scheduleObject.value?.dayOfMonth !== '*'
                    );
                default:
                    return false;
            }
        });

        return { scheduleChanged, scheduleObject, cronToString, isValidSchedule };
    },
});
