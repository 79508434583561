import { AxiosRequestConfig } from 'axios';
import { secured } from '@/app/config/axios';


const endpoint = '/api/retrieval';

export default {
    getData: (data: Array<any>) => ({ method: 'POST', url: `${endpoint}/visualisation`, data } as AxiosRequestConfig),
    getFile: (fileId: string) => secured.get(`/file/${fileId}/all`),
};
