import dayjs from 'dayjs';
import * as parser from 'cron-parser';
import utc from 'dayjs/plugin/utc';

export function useSchedule() {
    const validateDates = (startDate: string | Date, endDate: string | Date) => {
        if (new Date(endDate) > new Date(startDate)) {
            return '';
        } else {
            return 'End date must be after start date';
        }
    };

    const validateDateParameters = (
        startDate: string | Date,
        endDate: string | Date,
        dayOfWeek: string | number,
        month: string | number,
        dayOfMonth: string | number,
        hour: string | number,
        minute: string | number,
        retrieveOnce: boolean,
    ) => {
        const now = dayjs(new Date()).toISOString();

        let currentDate = null;

        if (startDate > now) {
            currentDate = startDate;
        } else {
            currentDate = now;
        }

        const options = {
            currentDate: currentDate,
            endDate: endDate,
            iterator: true,
            utc: true,
        };

        try {
            const interval = parser.parseExpression(`${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`, options);

            // eslint-disable-next-line no-constant-condition
            while (true) {
                try {
                    interval.next();
                    break;
                } catch (e) {
                    return `Invalid schedule: ${
                        retrieveOnce
                            ? 'The hour or minute chosen has already passed.'
                            : 'No valid executions between start and end date'
                    }`;
                }
            }
        } catch (err) {
            return 'Conflict in date parameters';
        }
        return '';
    };

    const validate = (scheduleConfig: any, retrieveOnce = false) => {
        const valid = {
            dates: '',
            dateParameters: '',
        };

        const startDate = dayjs.utc(scheduleConfig.startDate).startOf('day').toISOString();
        let endDate = null;

        if (retrieveOnce) {
            endDate = dayjs.utc(scheduleConfig.startDate).endOf('day').toISOString();
        } else {
            endDate = dayjs.utc(scheduleConfig.endDate).endOf('day').toISOString();
        }

        const dayOfWeek = scheduleConfig.dayOfWeek ?? '*';
        const month = scheduleConfig.month ?? '*';
        const dayOfMonth = scheduleConfig.dayOfMonth ?? '*';
        const hour = scheduleConfig.hour ?? '*';
        const minute = scheduleConfig.minute ?? '*';
        valid.dates = validateDates(startDate, endDate);

        valid.dateParameters = validateDateParameters(
            startDate,
            endDate,
            dayOfWeek,
            month,
            dayOfMonth,
            hour,
            minute,
            retrieveOnce,
        );
        return valid;
    };

    return { validate };
}
